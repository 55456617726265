import { gql } from 'apollo-angular';
import { OwnerOverview } from '@frontend/models';

export interface GraphQLResponseOwnerOverview {
  ownerOverview: OwnerOverview[];
}

export const GET_OWNER_OVERVIEW = gql`
  query GetOwnerOverview($bcid: String, $pcids: [String!]) {
    ownerOverview(bcid: $bcid, pcids: $pcids) {
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
        countrySettings
      }
      property {
        address {
          country
          city
          state
          zipCode
          streetName
          buildingNumber
          doorNumber
        }
      }
      rentalOffers {
        _id
        objectId
        state
        signatureData {
          date
          location
          signedDocumentUrl
        }
        tenantName
        totalRentPrice
        landlordAcceptance
        tenantRestriction {
          noLimitation
          limitationNotes
        }
        timeLimitation {
          date
        }
        generalInformation {
          rentalStart
          rentalDuration
          rentalEnd
        }
      }
      buyingOffers {
        _id
        objectId
        state
        signatureData {
          date
          location
          signedDocumentUrl
        }
        buyerName
        totalBuyPrice
        sellersAcceptance {
          sellerAcceptance
          changeAcceptanceUntil
          changeDetails
        }
        buyersAcceptanceWithChange {
          buyersAcceptanceWithChange
        }
        contractTerms {
          contractValidUntil
          alternativeValidity
          restriction
          restrictionDetails
        }
        propertyDetails {
          price
          includingAllTaxes
        }
        ownerCustomerPortal {
          showBuyerDecision
        }
      }
    }
  }
`;