import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {PublicPropertyOverviewActions} from "@frontend/store/public-property-overview/action-types";
import {catchError, switchMap} from "rxjs";
import {PropertyService, ThemeService} from "@frontend/services";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslationService} from "../../i18n/TranslationService";

@Injectable()
export class PublicPropertyOverviewEffects {

    public loadPublicPropertyOverview$ = createEffect(() =>
        this._actions$.pipe(
            ofType(PublicPropertyOverviewActions.LoadPublicPropertyOverview),
            switchMap(({ objectId }) =>
                this._propertyService.getPublicPropertyOverview$(objectId).pipe(
                    switchMap((data) => {
                        this._themeService.setCustomerPortalLanguage(data?.agent, data?.customerLanguage)
                        this._themeService.setAgentRelatedTheme(data?.agent);
                        return [
                            PublicPropertyOverviewActions.LoadPublicPropertyOverviewSucceeded({ payload: data }),
                        ];
                    }),
                    catchError(() => {
                        const message = this._translationService.instant('alerts.property_not_found');
                        const action = this._translationService.instant('alerts.close');
                        this._snackBar.open(message, action);
                        return [PublicPropertyOverviewActions.LoadPublicPropertyOverviewFailed()];
                    })
                )
             )
        )
    );


    constructor(
        private readonly _actions$: Actions,
        private readonly _propertyService: PropertyService,
        private readonly _themeService: ThemeService,
        private readonly _snackBar: MatSnackBar,
        private _translationService: TranslationService,
    ) {
    }
}