export enum Theme {
  Propup = 'propup',
  Remax = 'remax',
  Sulek = 'sulek',
  WohnCloud = 'wohncloud',
  Grasl = 'grasl',
  Immo4 = '4immobilien',
  ImmobilienMelk = 'immobilien-melk',
  Altera = 'altera',
  Finova = 'finova',
  Hagsteiner = 'hagsteiner',
  Qualis = 'qualis',
  Edelweiss = 'edelweiss',
  RemaxCollection = 'remax-collection',
  RemaxClassic = 'remax-classic',
  Prosch = 'prosch',
  AhImmobilien = 'ahImmobilien',
  AaaaImmobilien = 'aaaaImmobilien',
  Norisk = 'norisk',
  MayrImmo = 'mayrImmo',
  EraImmo = 'eraImmo'
}

export interface ThemeMetadata {
  logoUrl: string;
}