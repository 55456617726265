import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { OwnerOverviewActions } from './action-types';
import { CustomerService, PropertyService, ThemeService } from '@frontend/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import {TranslationService} from "../../i18n/TranslationService";

@Injectable()
export class OwnerOverviewEffects {
  public loadPropertyOverview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadOwnerOverview),
      switchMap(({ bcid, pcids }) =>
        this._propertyService.getOwnerOverview$(bcid, pcids).pipe(
          switchMap((data) => [
            OwnerOverviewActions.LoadOwnerOverviewSucceeded({ payload: data }),
          ]),
          catchError(() => [OwnerOverviewActions.LoadOwnerOverviewFailed()]),
        )
      ),
    )
  );

  public loadOwnersData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadOwnersData),
      switchMap(({ ownerIds }) =>
        forkJoin(ownerIds.map(id => this._customerService.getCustomerDetails$(id))).pipe(
          switchMap((data) => [
            OwnerOverviewActions.LoadOwnersDataSucceeded({ payload: data }),
            OwnerOverviewActions.LoadOwnerOverview({ pcids: ownerIds }),
          ]),
          catchError(() => [OwnerOverviewActions.LoadOwnersDataFailed()])
        )
      )
    )
  );

  public loadBusinessOwnerData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadBusinessOwnerData),
      switchMap(({ id }) =>
        this._customerService.getBusinessCustomerDetails$(id).pipe(
          switchMap((data) => [
            OwnerOverviewActions.LoadBusinessOwnerDataSucceeded({ payload: data }),
            OwnerOverviewActions.LoadOwnerOverview({ bcid: id }),
          ]),
          catchError(() => [OwnerOverviewActions.LoadBusinessOwnerDataFailed()]),
        ),
      )
    )
  );

  public loadRentalOfferPreviewData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadRentalOfferPreviewData),
      switchMap(({ id }) =>
        this._propertyService.getRentalOfferPreviewData$(id).pipe(
          switchMap((data) => {
            this._themeService.setCustomerPortalLanguage(data?.agent, '');
            this._themeService.setAgentRelatedTheme(data?.agent);
            return [OwnerOverviewActions.LoadRentalOfferPreviewDataSucceeded({ payload: data })];
          }),
          catchError(() => {
              const message = this._translationService.instant('alerts.offer_loading_failed');
              const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action, { duration: 5000 });
            return [OwnerOverviewActions.LoadRentalOfferPreviewDataFailed()];
          }),
        ),
      )
    )
  );


  public loadBuyingOfferPreviewData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadBuyingOfferPreviewData),
      switchMap(({ id }) =>
        this._propertyService.getBuyingOfferPreviewData$(id).pipe(
          switchMap((data) => {
            this._themeService.setCustomerPortalLanguage(data?.agent, '');
            this._themeService.setAgentRelatedTheme(data?.agent);
            return [OwnerOverviewActions.LoadBuyingOfferPreviewDataSucceeded({ payload: data })];
          }),
          catchError(() => {
              const message = this._translationService.instant('alerts.offer_loading_failed');
              const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action, { duration: 5000 });
            return [OwnerOverviewActions.LoadBuyingOfferPreviewDataFailed()];
          }),
        ),
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackBar: MatSnackBar,
    private readonly _themeService: ThemeService,
    private readonly _customerService: CustomerService,
    private readonly _propertyService: PropertyService,
    private _translationService: TranslationService,
  ) { }
}
