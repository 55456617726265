import { createReducer, on } from '@ngrx/store';
import { PropertyOverviewActions } from './action-types';
import {
  BusinessCustomerWithCustomerDetails,
  Customer,
  OverviewFeedback,
  PropertyOverview,
  PropertyOverviewDocuments,
} from '@frontend/models';

export interface State {
  propertyOverview: {
    data: PropertyOverview | null;
    error: boolean;
    loading: boolean;
  };
  propertyOverviewDocuments: PropertyOverviewDocuments | null;
  overviewCustomerData: {
    customers: Customer[];
    businessCustomer?: BusinessCustomerWithCustomerDetails | null;
  } | null;
  rentalOffer: {
    previewDocumentGeneration: boolean;
  }
  buyingOffer: {
    previewDocumentGeneration: boolean;
  }
  viewingPass: {
    previewDocumentGeneration: boolean;
  }
  feedback: OverviewFeedback | null;
  language: string;
}

export const initialState: State = {
  propertyOverview: {
    data: null,
    error: false,
    loading: false,
  },
  propertyOverviewDocuments: null,
  overviewCustomerData: null,
  rentalOffer: {
    previewDocumentGeneration: false,
  },
  buyingOffer: {
    previewDocumentGeneration: false,
  },
  viewingPass: {
    previewDocumentGeneration: false,
  },
  feedback: null,
  language: 'AT',
};

const propertyOverviewReducer = createReducer(
  initialState,

  on(PropertyOverviewActions.LoadPropertyOverview, (state) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      loading: true,
      error: false,
    },
  })),
  on(PropertyOverviewActions.LoadPropertyOverviewSucceeded, (state, { payload }) => ({
    ...state,
    propertyOverview: {
      loading: false,
      error: false,
      data: payload,
    },
  })),
  on(PropertyOverviewActions.LoadPropertyOverviewFailed, (state) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      error: true,
      loading: false,
    },
  })),

  on(PropertyOverviewActions.LoadPropertyOverviewDocumentsSucceeded, (state, { payload }) => ({
    ...state,
    propertyOverviewDocuments: payload
  })),
  on(PropertyOverviewActions.LoadPropertyOverviewDocumentsFailed, (state) => ({
    ...state,
    propertyOverviewDocuments: {},
  })),

  on(PropertyOverviewActions.LoadOverviewFeedbackSucceeded, (state, { payload }) => ({
    ...state,
    feedback: payload
  })),
  on(PropertyOverviewActions.LoadOverviewFeedbackFailed, (state) => ({
    ...state,
    feedback: {
      waitingForFeedback: false,
    },
  })),

  on(PropertyOverviewActions.LoadPropertyOverviewCustomersSucceeded, (state, { payload }) => ({
    ...state,
    overviewCustomerData: { customers: payload },
  })),
  on(PropertyOverviewActions.LoadPropertyOverviewCustomersFailed, (state) => ({
    ...state,
    overviewCustomerData: { customers: [] },
  })),

  on(PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerSucceeded, (state, { payload }) => ({
    ...state,
    overviewCustomerData: {
      customers: payload.customers,
      businessCustomer: payload,
    },
  })),
  on(PropertyOverviewActions.LoadPropertyOverviewBusinessCustomerFailed, (state) => ({
    ...state,
    overviewCustomerData: {
      customers: [],
      businessCustomer: null
    },
  })),

  on(PropertyOverviewActions.GenerateRentalOfferPreviewDocument, (state) => ({
    ...state,
    rentalOffer: {
      ...state.rentalOffer,
      previewDocumentGeneration: true,
    }
  })),
  on(PropertyOverviewActions.GenerateRentalOfferPreviewDocumentFailed, (state) => ({
    ...state,
    rentalOffer: {
      ...state.rentalOffer,
      previewDocumentGeneration: false,
    },
  })),
  on(PropertyOverviewActions.GenerateRentalOfferPreviewDocumentSucceeded, (state, { payload }) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      data: {
        ...state.propertyOverview.data,
        userData: {
          ...state.propertyOverview.data?.userData,
          rentalOffer: {
            ...state.propertyOverview.data?.userData?.rentalOffer,
            documentPreviewUrl: payload.storageUrl,
          }
        }
      }
    },
    rentalOffer: {
      ...state.rentalOffer,
      previewDocumentGeneration: false,
    },
  })),

  on(PropertyOverviewActions.GenerateBuyingOfferPreviewDocument, (state) => ({
    ...state,
    buyingOffer: {
      ...state.buyingOffer,
      previewDocumentGeneration: true,
    }
  })),
  on(PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentFailed, (state) => ({
    ...state,
    buyingOffer: {
      ...state.buyingOffer,
      previewDocumentGeneration: false,
    },
  })),
  on(PropertyOverviewActions.GenerateBuyingOfferPreviewDocumentSucceeded, (state, { payload }) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      data: {
        ...state.propertyOverview.data,
        buyingOffer: {
          ...state.propertyOverview.data?.buyingOffer,
          data: {
            ...state.propertyOverview.data?.buyingOffer?.data,
            documentPreviewUrl: payload.storageUrl,
          },
        },
      }
    },
    buyingOffer: {
      ...state.buyingOffer,
      previewDocumentGeneration: false,
    },
  })),

  on(PropertyOverviewActions.GenerateViewingPassPreviewDocument, (state) => ({
    ...state,
    viewingPass: {
      ...state.viewingPass,
      previewDocumentGeneration: true,
    }
  })),
  on(PropertyOverviewActions.GenerateViewingPassPreviewDocumentFailed, (state) => ({
    ...state,
    viewingPass: {
      ...state.viewingPass,
      previewDocumentGeneration: false,
    },
  })),
  on(PropertyOverviewActions.GenerateViewingPassPreviewDocumentSucceeded, (state, { payload }) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      data: {
        ...state.propertyOverview.data,
        userData: {
          ...state.propertyOverview.data?.userData,
          viewingPass: {
            ...state.propertyOverview.data?.userData?.viewingPass,
            documentPreviewUrl: payload.storageUrl,
          }
        }
      }
    },
    viewingPass: {
      ...state.viewingPass,
      previewDocumentGeneration: false,
    },
  })),

  on(PropertyOverviewActions.UpdateCustomersData, (state, { payload }) => ({
    ...state,
    overviewCustomerData: {
      customers: payload.customers,
      businessCustomer: payload.businessCustomer,
    },
  })),
  on(PropertyOverviewActions.UpdateCustomerLanguage, (state, {customerLanguage}) => ({
    ...state,
    language: customerLanguage,
    propertyOverview: {
      ...state.propertyOverview,
      loading: true,
    },
  })),
  on(PropertyOverviewActions.UpdateCustomerLanguageSucceeded, (state, {customerLanguage}) => ({
    ...state,
    language: customerLanguage,
    propertyOverview: {
      ...state.propertyOverview,
      loading: false,
    },
  })),
  on(PropertyOverviewActions.UpdateCustomerLanguageFailed, (state) => ({
    ...state,
    propertyOverview: {
      ...state.propertyOverview,
      loading: false,
      error: true,
    },
  })),
);

export const reducer = (state: State | undefined, action: any) =>
  propertyOverviewReducer(state, action);

export const getPropertyOverview = (state: State) => state.propertyOverview.data;
export const getPropertyOverviewError = (state: State) => state.propertyOverview.error;
export const getPropertyOverviewLoading = (state: State) => state.propertyOverview.loading;
export const getPropertyOverviewDocuments = (state: State) => state.propertyOverviewDocuments;
export const getOverviewFeedback = (state: State) => state.feedback;
export const getPropertyOverviewCustomerData = (state: State) => state.overviewCustomerData;
export const getRentalOfferPreviewDocumentGeneration = (state: State) => state.rentalOffer.previewDocumentGeneration;
export const getBuyingOfferPreviewDocumentGeneration = (state: State) => state.buyingOffer.previewDocumentGeneration;
export const getViewingPassPreviewDocumentGeneration = (state: State) => state.viewingPass.previewDocumentGeneration;
export const getLanguage = (state: State) => state.language;

