import { gql } from 'apollo-angular';
import { PropertyOverviewDocuments } from '@frontend/models';

export interface GraphQLResponsePropertyOverviewDocuments {
  propertyOverviewDocuments: PropertyOverviewDocuments;
}

export const GET_PROPERTY_OVERVIEW_DOCUMENTS = gql`
  query GetPropertyOverviewDocuments($id: String!) {
    propertyOverviewDocuments(id: $id) {
      viewingPass {
        id
        documentUrl
        location
        date
        vpType
      }
      handoverProtocol {
        id
        documentUrl
        location
        date
      }
      rentalOffer {
        id
        state
        documentUrl
        location
        date
        showOwnerDecision
        isAcceptedByOwner
      }
      buyingOffers {
        id
        state
        documentUrl
        location
        date
        showOwnerDecision
        isAcceptedByOwner
      }
    }
  }
`;