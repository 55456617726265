import { gql } from 'apollo-angular';

export interface GraphQLResponseUpdateCustomerLanguage {
    updateCustomerLanguage: string;
}

export const UPDATE_CUSTOMER_LANGUAGE = gql`
    mutation UpdateCustomerLanguage($accessId: String!, $customerLanguage: String!) {
      updateCustomerLanguage(accessId: $accessId, customerLanguage: $customerLanguage)
    }
`;