import {CountrySettings} from "../types";
import {Language} from "./config";

export const mapCountrySettingsToLanguage = (countrySettings: CountrySettings | undefined | null): Language => {
  switch (countrySettings) {
    case CountrySettings.ES:
      return Language.ES;
    case CountrySettings.IT:
      return Language.IT;
    case CountrySettings.FR:
      return Language.FR;
    case CountrySettings.DE:
      return Language.DE;
    case CountrySettings.EN:
      return Language.EN;
    case CountrySettings.AT:
    default:
      return Language.AT;
  }
};